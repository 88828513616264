import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { XCircle} from 'phosphor-react'
import { toast } from 'react-toastify'
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {

  const handleSubmit = () => {
    toast.dark('Not implemented yet!', {
      icon: <XCircle size={32} color="#ff3838" weight="fill" />,
    })
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              <img src={contactImg} alt="Contact Us"/>              
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
                <h2>Get In Touch</h2>
                <form >
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" placeholder="First Name"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" placeholder="Last Name"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" placeholder="Email Address"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" placeholder="Phone No."/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" placeholder="Message"></textarea>
                      <button onClick={handleSubmit} type="button"><span>Send</span></button>
                    </Col>                    
                  </Row>
                </form>
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
