import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Newsletter } from "./Newsletter";
import { XCircle} from 'phosphor-react'
import { toast } from 'react-toastify'
export const MailchimpForm = () => {

  const handleSubmit = () => {
    toast.dark('Not implemented yet!', {
      icon: <XCircle size={32} color="#ff3838" weight="fill" />,
    })
  };

  return (
    <>
      <MailchimpSubscribe
        url={'postUrl'}
        onClick={handleSubmit}
        render={({ handleSubmit }) => (
          <Newsletter
            onSubmit={handleSubmit}
            />
        )}
        />
    </>
  )
}
