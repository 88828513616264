
import IMG1 from '../assets/img/portfolio1.jpg'
import IMG2 from '../assets/img/portfolio2.jpg'
import IMG3 from '../assets/img/portfolio3.jpg'
import IMG4 from '../assets/img/portfolio4.jpg'
import IMG5 from '../assets/img/portfolio5.png'
import IMG6 from '../assets/img/portfolio6.jpg'

const data =[
  {
    id:1,
    image: IMG1,
    title:'Crypto Currency Dashboard & Financial Visualization',
  },
  {
    id:2,
    image: IMG2,
    title:'Charts templates & infographics in Figma',
  },
  {
    id:3,
    image: IMG3,
    title:'Figma dashboard UI kit for data design web apps',
  },
  {
    id:4,
    image: IMG4,
    title:'Maintaing tasks and tracking progress',
  },
  {
    id:5,
    image: IMG5,
    title:'Charts templates & infographics in Figma',
  },
  {
    id:6,
    image: IMG6,
    title:'Software House Web - App | UI UX Design',
  },
]
export function Portfolio() {
  return (
    <section className="portfolio-container" id='portfolio'>     
      <h2>Projects</h2>     
      <p>
        Come put your services on a fully responsive website, working on mobile, tablet, notebook and computers.
          The development time is proportional to the size of the site.
      </p> 
      <div className=" portfolio_container">
        {
          data.map(({id,image, title}) => {
            return(
              <article key={id} className="portfolio_item">
                <div className="portfolio_item-image">
                  <img src={image} alt=""></img>
                </div>
                <h3>{title}</h3>
                <div className="btnList">
                  <a href="https://github.com/herbertcarnaubadesouza?tab=repositories">
                    <button className='btnGithub'>Github</button>
                  </a>
                  <a href="https://github.com/herbertcarnaubadesouza?tab=repositories">
                    <button className='btnLive'>Hire Service</button>
                  </a>
                </div>
              </article> 
            )
          })
        }      
      </div>
    </section>
  );
}

